const actions = {
  SIGN_IN: "SIGN_IN",
  SUPERADMIN_SIGN_IN: "SUPERADMIN_SIGN_IN",
  MODAL: "MODAL",
  CHECK_AUTH_TOKEN_TIME: "CHECK_AUTH_TOKEN_TIME",
  SIGN_OUT: "SIGN_OUT",
  SET_AUTH_LOADING: "SET_AUTH_LOADING",
  SET_STATE: "SET_STATE",
  UPDATE_PROFILE: "UPDAT_PROFILE",
  FORGOT_PASSWORD_LINK: "FORGOT_PASSWORD_LINK",
  RESET_PASSWORD: "RESET_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  AUTH_LOADING: "AUTH_LOADING",
  UPDATE_AUTH_STATE: "UPDATE_AUTH_STATE",
};
export default actions;
