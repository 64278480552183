const actions = {
  GET_CANDIDATE: "GET_CANDIDATE",
  GET_SAVED_CANDIDATE: "GET_SAVED_CANDIDATE",
  GET_CLIENT_CANDIDATE: "GET_CLIENT_CANDIDATE",
  SET_CANDIDATE: "SET_CANDIDATE",
  SEND_MAIL_TO_SELECTED_CANDIDATES: "SEND_MAIL_TO_SELECTED_CANDIDATES",
  SET_SELECTED_FOR_EMAIL_CANDIDATE: "SET_SELECTED_FOR_EMAIL_CANDIDATE",
  HIRED_CANDIDATE: "HIRED_CANDIDATE",
  CREATE_CANDIDATE: "CREATE_CANDIDATE",
  CREATE_CANDIDATE_CSV: "CREATE_CANDIDATE_CSV",
  CREATE_PUBLIC_CANDIDATE: "CREATE_PUBLIC_CANDIDATE",
  LOADING: "LOADING",
  DELETE_CANDIDATE: "DELETE_CANDIDATE",
  IS_SENT: "IS_SENT",
  IS_NOT_SENT: "IS_NOT_SENT",
  UPDATE_CANDIDATE: "UPDATE_CANDIDATE",
  UPDATE_CANDIDATE_PUBLIC: "UPDATE_CANDIDATE_PUBLIC",
  CHECK_CANDIDATE: "CHECK_CANDIDATE",
  FILTER_CANDIDATE: "FILTER_CANDIDATE",
  CREATE_ERROR: "CREATE_ERROR",
  CANDIDATE_STATUS: "CANDIDATE_STATUS",
  PLAN_EXPIRE: "PLAN_EXPIRE",
  CREATE_PUBLIC_CANDIDATE_POPUP: "CREATE_PUBLIC_CANDIDATE_POPUP",
  GET_CLIENT_CANDIDATE_LOADER: "GET_CLIENT_CANDIDATE_LOADER",
  GET_SAVED_CANDIDATE_LOADER: "GET_SAVED_CANDIDATE_LOADER",
  GET_BEST_MATCHES_CANDIDATE: "GET_BEST_MATCHES_CANDIDATE",
  GET_BEST_MATCHES_CANDIDATE_LOADER: "GET_BEST_MATCHES_CANDIDATE_LOADER"
};
export default actions;
