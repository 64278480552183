const actions = {
  PAYMENT_LOADING: "PAYMENT_LOADING",
  PAYMENT_SET_STATE: "PAYMENT_SET_STATE",
  CREATE_ORDER_INSTANCE: "CREATE_ORDER_INSTANCE",
  CAPTURE_PAYMENT: "CAPTURE_PAYMENT",
  ClEAR_PAYMENT_STATE: "ClEAR_PAYMENT_STATE",
  PAYMENT_SUCCESSFUL: "PAYMENT_SUCCESSFUL",
  PAYMENT_SUCCESSFUL_MAIL: "PAYMENT_SUCCESSFUL_MAIL",
  PAYMENT_STATUS: "PAYMENT_STATUS",
  CREATE_PAYMENT: "CREATE_PAYMENT",
  GET_PAYMENTS_DETAILS: "GET_PAYMENTS_DETAILS",
};
export default actions;
